import React from "react";
import Reveal from 'react-reveal';

interface ExperienceTitle {
    text: string;
}

interface ExperienceProps {
    font_awesome_icon: string;
    experience_title: ExperienceTitle;
    experience_description: string;
}

interface Props {
    experiences: ExperienceProps[];
}

const HomeExperience = ({ experiences }: Props) => {
  return (
    <div>
        <div className="experience-container">
            <h1>The Global Inheritance Experience</h1>
            <div className="experience-inner">
                {experiences.map((experience, i) => {
                  let iconClass = `fa ${experience.font_awesome_icon}`;

                  return (
                      <Reveal effect="animated fadeInUp" key={i}>
                          <div className="experience-module">
                            <div className="experience-title">
                                <i className={iconClass} aria-hidden="true"></i><h3>{experience.experience_title[0]?.text}</h3>
                            </div>
                            <div className="experience-desc">
                                {experience.experience_description}
                            </div>
                          </div>
                      </Reveal>
                  );
              })}
            </div>
        </div>
    </div>
  );
};

export default HomeExperience;