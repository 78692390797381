import React from "react";
import Reveal from 'react-reveal';
import Slider from 'react-slick';
import { lightenDarkenColor, truncateText } from "../../utils";

const HomePrograms = ({ programs }) => {
  const gallerySettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: false,
    swipe: false,
    arrows: true,
    centerMode: false,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
  };

  const renderProgramModule = (program) => {
    const thumbImage = program.body.find(x => x.type === "hero_image_slice").primary.hero_image.url;
    const iconImage = program.body.find(x => x.type === "icon_slice").primary.icon.url;
    const themeColor = program.body.find(x => x.type === "theme_color_slice").primary.color;
    const programDescription = program.body.find(x => x.type === "intro_slice").primary.intro_description[0].text;
    const programName = program.program_name;
    return (
      <Reveal effect="animated zoomIn" id={`program-${program}`} key={programName}>
        <div className="module slideshow">
          <div className="module-shadow-container">
              <div className="thumb" style={{backgroundImage: `url(${thumbImage})`}}>
                  <div className="icon" style={{background: `linear-gradient(-220deg, ${themeColor} 0%, ${lightenDarkenColor({ col: themeColor, amt: -35 })} 100%)`}}>
                      <div className="icon-svg" style={{ backgroundImage: `url(${iconImage})` }}></div>
                  </div>
              </div>

              <div className="inner">
                  <h1>{programName}</h1>
                  <p dangerouslySetInnerHTML={{ __html: truncateText({ input: programDescription, len: 25}) }} />
              </div>

              <a href={`/programs/${programName.toLowerCase().replace(/\s+/g, '-')}`} className="no-effects">
                  <div className="btn small primary">
                    <span>Learn More</span>
                  </div>
              </a>
          </div>
        </div>
      </Reveal>
    );
  };

  return (
    <div>
      <div className="modules-container">
          <div className="home-section-title-container">
              <h1 className="home-section-title">How</h1>
              <span />
          </div>
          <div className="modules-container-inner">
            <Slider {...gallerySettings}>
              {programs.map((program) => renderProgramModule(program.home_programs))}
            </Slider>
          </div>
      </div>
    </div>
  )
};

export default HomePrograms;