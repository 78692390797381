import React from "react"
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/Hero";
import HomeExperience from "../components/HomeExperience";
import HomePrograms from "../components/HomePrograms";
import JoinModule from "../components/JoinModule";

const HeaderSlides = ({ content }) => {
  const { primary, fields } = content;
  const { youtube_embed_id } = primary;
  return <Hero slides={fields} embed={youtube_embed_id} />
}

const ExperienceSection = ({ content }) => {
  const { fields } = content;
  return <HomeExperience experiences={fields} />
}

const HeadlineSlice = ({ content }) => {
  const { primary } = content;
  const { headline_text } = primary;
  return (
    <div className="container">
      <div className="home-intro">
        <p>{headline_text}</p>
      </div>
    </div>
  )
};

const HomeProgramsList = ({ content }) => {
  const { fields } = content;
  return (
    <HomePrograms programs={fields} />
  )
}

const sliceComponents = {
  header_slides: HeaderSlides,
  headline_slice: HeadlineSlice,
  experience_slice: ExperienceSection,
  programs_list: HomeProgramsList,
};

export default ({ data }) => {  
  return (
    <Layout>
      <div className="home">
        {data?.prismic?.allHomepages?.edges[0]?.node?.body.map((slice) => {
          if (!slice?.type) return null;
          const SliceComponent =
            sliceComponents[slice.type];

            if (SliceComponent) {
              return (
                <SliceComponent
                  content={slice}
                  key={slice.id}
                />
              );
            }

            return (
              <div key={slice.id}>
                No component has been configured for this slice: {slice.type}
              </div>
            );
        })}
        <JoinModule />
      </div>
    </Layout>
  )
};

export const query = graphql`
{
  prismic {
    allHomepages {
      edges {
        node {
          body {
            __typename
            ... on PRISMIC_HomepageBodyHeader_slides {
              type
              label
              primary {
                youtube_embed_id
              }
              fields {
                header_slide_pretitle
                header_slide_title
                header_slide_cta
                header_slide_link {
                  _linkType
                  ... on PRISMIC__ExternalLink {
                    url
                    target
                  }
                }
              }
            }
            ... on PRISMIC_HomepageBodyHeadline_slice {
              type
              label
              primary {
                headline_text
              }
            }
            ... on PRISMIC_HomepageBodyExperience_slice {
              type
              label
              fields {
                font_awesome_icon
                experience_title
                experience_description
              }
            }
            ... on PRISMIC_HomepageBodyPrograms_list {
              type
              label
              fields {
                home_programs {
                  ... on PRISMIC_Program {
                    program_name
                    _linkType
                    body {
                      ... on PRISMIC_ProgramBodyHero_image_slice {
                        type
                        label
                        primary {
                          hero_image
                        }
                      }
                      ... on PRISMIC_ProgramBodyIntro_slice {
                        type
                        label
                        primary {
                          intro_description
                        }
                      }
                      ... on PRISMIC_ProgramBodyIcon_slice {
                        type
                        label
                        primary {
                          icon
                        }
                      }
                      ... on PRISMIC_ProgramBodyTheme_color_slice {
                        type
                        label
                        primary {
                          color
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
