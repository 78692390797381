import React from "react";
import Slider from "react-slick";
import { Parallax, Background } from 'react-parallax';

interface SlideTitle {
  type: string;
  text: string;
}

interface SlideLink {
  url: string;
}

interface SlideProps {
  header_slide_pretitle: string;
  header_slide_cta: string;
  header_slide_title: SlideTitle[];
  header_slide_link: SlideLink;
}

interface Props {
  slides: SlideProps[];
  embed: string;
}

const Hero = ({ slides, embed }: Props) => {
  const gallerySettings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: false,
    pauseOnHover: false
  };
  return (
    <Parallax className="video-bg" strength={400}>
      <Background>
          <div style={{height: '100vh'}}>
            <div className="embed-container header">
            <iframe src={`https://player.vimeo.com/video/${embed}?background=1&autoplay=1&muted=1&loop=1&byline=0&title=0&controls=0&playsinline=1`}></iframe>
            </div>
            <div className="video-overlay" />
            <Slider {...gallerySettings} className="header-slides-container">
              {slides.map((slide: SlideProps) => (
                <div className="header-slide" key={slide.header_slide_title[0]?.text}>
                  <h3>{slide.header_slide_pretitle}</h3>

                  <h1>{slide.header_slide_title[0]?.text}</h1>

                  <a href={slide.header_slide_link?.url} className="no-effects">
                      <div className="btn primary small">
                          <span>{slide.header_slide_cta}</span>
                      </div>
                  </a>
                </div>
              ))}
            </Slider>
          </div>
      </Background>
    </Parallax>
  )
}

export default Hero;